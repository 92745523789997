import MyContext ,{root} from "../store/MyContext";
import { Canvas, useFrame } from "@react-three/fiber";
import { useHistory,Link } from "react-router-dom";
import {Html, OrbitControls, TrackballControls ,PerspectiveCamera,SpotLight,TransformControls,Stats, calcPosFromAngles} from "@react-three/drei";
import React, { useContext, useRef,useState,useEffect } from "react";
import * as THREE from "three";

import Balloon from "./Balloon";


const PartsDraw =React.forwardRef(({ meshsArry,whenSelected,editOnPart ,uniAssembly,AcvMech,myCtx,history,camera,p,animating,datum},ref) => {

  
  //const [update,setUpdate]=useState(false ) 
    const refLine= useRef();
    const refDimension= useRef();
    const hoverColor =0x2265A1
   const refmesh =[];
   let  clickCount=0;

   const points = [new THREE.Vector3( 0, 0, 0 ),new THREE.Vector3( 1, 1, 1 )];
   
   const planeGeom = new THREE.PlaneGeometry( 1, .001 );
  

 
   const vertices = new Float32Array( [
     -.01, -.01,  .01,
      .01, -.01,  .01,
      .01,  .01,  .01,
   
      .01,  .01,  .01,
     -.01,  .01,  .01,
     -.01, -.01,  .01
   ] );

   const BufferAtt = new THREE.BufferAttribute( vertices, 3 )
   




  const color2 = new THREE.Color( 0x222222 );
const lastPoint={point:new THREE.Vector3( 0, 0, 0 ),object:{name:0}};


//      useEffect(() => {  
    
// ref.current={Update:()=>{},Update2:()=>{},animating:false}
//         ref.current.Update=function(){
//       setUpdate(!update)}     
//       ref.current.Update2=ref2.current.Update
//   },[ref,update]) 
  
    
 

    
    function eachMesh(e){
      refmesh.push(e)
    
    }
    function datumClick(e){
      if( myCtx.isDatumOn==="dimension"){
      if( clickCount===0){points[0].copy(e.point)}

      if( clickCount===1){
        let title = prompt("Dimension will be set to :", refDimension.current.textContent );
        if (title !== null && title !== "") {
      datum.push({
      ax:e.point.x,
      ay:e.point.y,
      az:e.point.z,

      bx:points[0].x,
      by:points[0].y,
      bz:points[0].z,
      uniAssemblyID:uniAssembly.origin,
      title:title, 
      updated_at:"not",
      created_at:"not",
      type:"dimension"
    })
     }
     myCtx.setDatumOn(false)   
       
        }
       else
       { clickCount++; }
      }else{
        let title = prompt("Note set title:", "" );
        if (title !== null && title !== "") {
          Set_st(meshsArry,myCtx.getRadius())
          datum.push({
            ax:myCtx.isexploded()?e.point.x+meshsArry[e.object.name].x_st:e.point.x,
            ay:myCtx.isexploded()?e.point.y+meshsArry[e.object.name].y_st:e.point.y,
            az:myCtx.isexploded()?e.point.z+meshsArry[e.object.name].z_st:e.point.z,
            bx:meshsArry[e.object.name].x_st,
            by:meshsArry[e.object.name].y_st,
            bz:meshsArry[e.object.name].z_st,
        
            uniAssemblyID:uniAssembly.origin,
            title:title, 
            updated_at:"not",
            created_at:"not",
            type:"documentation"
          })
        }
        myCtx.setDatumOn(false) 
      }

}


    function onPointerMove(e){
      if( myCtx.isDatumOn){ 
        lastPoint.point.copy(e.point)
        lastPoint.object.name= e.object.name
      if(clickCount===1){
     
       
        const b=points[0]
        const a=e.point
                refLine.current.quaternion.setFromUnitVectors(new THREE.Vector3(1,0,0).normalize(),new THREE.Vector3().subVectors(a,b).normalize());
                refLine.current.scale.x= a.distanceTo(b)
                refLine.current.scale.y=50 
                refLine.current.position.addVectors(a,b).multiplyScalar(0.5);
                refLine.current.scale.x < 1? ( refDimension.current.textContent= ((refLine.current.scale.x*1000).toFixed() + " mm" )):
                 ((refDimension.current.textContent= (refLine.current.scale.x).toFixed(2)  + " m" ))
              
              }else{
                refLine.current.position.copy(e.point)
               
              }
        }
    }



    // const arrLength = meshsArry.length;
    // const elRefs = React.useRef([]);
    
    // if (elRefs.current.length !== arrLength) {
    //   // add or remove refs
    //   elRefs.current = Array(arrLength)
    //     .fill()
    //     .map((_, i) => elRefs.current[i] || createRef());
    // }

 
    
    function AcvMechRef (e){

        AcvMech.e = e;
      };


    function hover(e) { if(e.object!==undefined){e.stopPropagation() ;e.object.material.color.add(color2);}
    else if (AcvMech.e !== null ) AcvMech.e.children[e].material.color.add(color2)}
   

    function unHover(e) {if(e.object!==undefined){e.stopPropagation(); e.object.material.color.sub(color2)}
    
    else if (AcvMech.e !== null ) AcvMech.e.children[e].material.color.sub(color2)}

    function PointerMissed(e) {(myCtx.selectSt !== null)?myCtx.setSelectSt(null):explod()}
    

    
    
    function PartClick(e) {
     
        e.object!==undefined && e.stopPropagation();
      
       
        // meshsArry[0].x=e.point.x
        // meshsArry[0].y=e.point.y
        // meshsArry[0].z=e.point.z

        AcvMech.e.children.map((m) => {
          m.material.opacity = 0.2;
        });
const k=e.object!==undefined?e.object:AcvMech.e.children[e]

        k.material.opacity = 1;
      
      

    if(!myCtx.isEditOn){
   
          if (k.name === myCtx.selectSt && k.geometry!==undefined) {
        if(camera.position.distanceToSquared(k.position) <  Math.pow( Math.max(k.geometry.boundingSphere.radius,k.geometry.boundingSphere.radius/camera.aspect),2)*4.5 
        ||e.object===undefined
        )
        {
          history.push("/?uniAssembly=" + meshsArry[myCtx.selectSt].id)
      
      }else{
         
         whenSelected(1.1,k.name)
        } 
        
          }else {whenSelected(1.1,k.name);myCtx.setSelectSt(k.name)}

    }else if (k.name === myCtx.selectSt) {editOnPart(k)
          
        } else {
          myCtx.setSelectSt(k.name);whenSelected(1.1)}
      
          
      }
    


      
      function onDClick(e) {
        e.stopPropagation();
        if (e.object.name === myCtx.selectSt) {
          history.push("/?uniAssembly=" + meshsArry[myCtx.selectSt].id);
        }
      }

      function explod() {// if (ary !== []){
       
        animating(false)
         whenSelected(2)
        myCtx.setexplod();
        
        
  
          Set_st(meshsArry,myCtx.getRadius())
        
       
       
       
    
        p.xpP = 1 - p.xpP;
       
        xpDr = myCtx.isexploded()? -1:1;
      }
    
      
        
     
       
       let frame=0
       let xpDr = myCtx.isexploded()? -1:1;
         
     


    useFrame(() => {
        if (AcvMech.e.children.length===meshsArry.length){
//    frame+=1
//    if (frame===3){
//   //  light.current.position.copy(orbitControls.current.object.position)
//    frame=0
//  }
      if (p.xpP > 0) {
        let t =  1 / 15;
       
        if (p.xpP < t) {
          t = p.xpP;
         
        }
     
        AcvMech.e.children.forEach((o, index) => {
          o.position.x += (t * xpDr * meshsArry[index].x_st) ;
          o.position.y += (t * xpDr * meshsArry[index].y_st) ;
          o.position.z += (t * xpDr * meshsArry[index].z_st) ;
        
        });
        p.xpP -= t;
       if(p.xpP===0){//  
        !myCtx.isexploded() && whenSelected(1.1) ;//animating(true);    //setUpdate(!update);
      }
      }
    }});
 
    return (
      <>
            {/* <MyContext.Consumer> 
        {({selectSt}) => (<>
          */}
        <mesh
     
    
        
          onPointerOver={hover}
          onPointerOut={unHover}
          onPointerMissed={(myCtx.isDatumOn && meshsArry.length) ?(e)=>datumClick(lastPoint):PointerMissed}
          ref={AcvMechRef}
          
          onClick={myCtx.isDatumOn?datumClick:PartClick}
          //
         // onContextMenu={(e)=>myCtx.setDatumOn(!myCtx.isDatumOn)  }
          onDoubleClick={onDClick}
          onPointerMove={onPointerMove}
        >
         
          {
          meshsArry.map((mess, index) => (mess.created_at!=="delete" &&
            <mesh
              key={index}
              name={index}
              geometry={(mess.geometry==null||mess.geometry===undefined)?new THREE.BufferGeometry():mess.geometry}
              ref={eachMesh}
              position={
                myCtx.isexploded()? [
                  mess.x-mess.x_st,
                  mess.y-mess.y_st,
                  mess.z-mess.z_st,
                ]:[mess.x,mess.y,mess.z]}
          
               >
              
              <meshStandardMaterial
                
                color={mess.color}
                 //side = {THREE.FrontSide }
                vertexColors={mess.color ==null?true:false}
              
                opacity={
                  index === myCtx.selectSt || myCtx.selectSt === null ? 1 : 0.2
                }
                transparent={true}
              />
                     
            
              {" "}
            </mesh>
          ))}
        </mesh> 
        <Balloon
        meshsArry={meshsArry}
        AcvMech = {refmesh}
        myCtx={myCtx}
        selectSt={myCtx.selectSt}
ref={ref}
        onPointerOver={hover}
        onPointerOut={unHover}
        onPointerMissed={PointerMissed}
        onClick={PartClick}
        onDoubleClick={onDClick}

        ></Balloon>

{myCtx.isDatumOn && <mesh geometry={planeGeom}ref={refLine} >
 <meshStandardMaterial color={0xffff00} side= {THREE.DoubleSide} 
  opacity={
     0.5
  }
  transparent={true}
 />
 <Html style={{pointerEvents:'none'}} ><label ref={refDimension} >Click to Measures </label></Html>
   </mesh>}

     {/* </> 
     )}
         
         </MyContext.Consumer>  */}
      </>
    );
  });
  export default PartsDraw ;

  function Set_st(meshsArry, r) {

    let aver = new THREE.Vector3(0,0,0);
    meshsArry.forEach((o) => {
      aver.x= aver.x+o.x
      aver.y= aver.y+o.y
      aver.z= aver.z+o.z
    })
    aver=aver.divideScalar(meshsArry.length);

    meshsArry.forEach((o) => {

      if (
        o.x_st == null &&
        o.y_st == null &&
       o.z_st == null 
      
      ) 
      {
        
        let pos = new THREE.Vector3(o.x,o.y,o.z);
        pos=pos.subVectors(aver,pos).normalize().multiplyScalar(r);
   
        o.x_st = pos.x;
        o.y_st = pos.y;
       o.z_st = pos.z;
      }
    });
  }

