import  {SimplifyModifier} from "three/examples/jsm/modifiers/SimplifyModifier.js";
import {mergeVertices,getNormal} from './myMergeVertices'
import * as THREE from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
function MakeHollow(m) {

  // m.position.x =0;
  // m.position.y =0;
  // m.position.z =0;
    
    const indices = m.geometry.getIndex();


    const positions = m.geometry.getAttribute( 'position' );
    const normals = m.geometry.getAttribute( 'normal' );
    const newIndices = [];
    m.material.transparent=false
    m.material.side = THREE.BackSide;
    let nextIndex = 0;

    var centor = new THREE.Vector3(0, 0, 0);
    m.geometry.computeBoundingSphere();
    
    var radius = m.geometry.boundingSphere.radius;
  
    const newPositions = [];
    const newNormals = [];
    var centroid = new THREE.Vector3(0, 0, 0);
    var dirc = new THREE.Vector3(0, 0, 0);
    var verticesA = new THREE.Vector3(0, 0, 0);
    var verticesB = new THREE.Vector3(0, 0, 0);
    var verticesC = new THREE.Vector3(0, 0, 0);
  
    var NormalA = new THREE.Vector3(0, 0, 0);
    var NormalB = new THREE.Vector3(0, 0, 0);
    var NormalC = new THREE.Vector3(0, 0, 0);

    let orgn;
    console.log(radius);
    var raycaster = new THREE.Raycaster(centroid, dirc, 0.001, radius);
    var intersects = raycaster.intersectObject(m);
    // const indexAttr = geometry.getIndex();
    const positionAttr = m.geometry.attributes.position.array;
    const normalAttr = m.geometry.attributes.normal.array;
    const indexCount = positionAttr.length;
  
    console.log("start :"+positions.count) 
    for ( let i = 0; i < indices.count; i+=3 )
    {
      
      const a = indices.getX( i ) 
      const b = indices.getX( i+1 )
      const c = indices.getX( i+2 )
    

      //var lineGo = new THREE.Geometry();
      verticesA.set(positions.getX(a)+m.position.x, positions.getY(a)+m.position.y, positions.getZ(a)+m.position.z);
      verticesB.set(positions.getX(b)+m.position.x, positions.getY(b)+m.position.y, positions.getZ(b)+m.position.z);
      verticesC.set(positions.getX(c)+m.position.x, positions.getY(c)+m.position.y, positions.getZ(c)+m.position.z);
      
  
dirc.subVectors(verticesC,verticesB)
NormalA.subVectors(verticesA,verticesB)
dirc.cross(NormalA)
dirc.normalize();


      NormalA.set(normals.getX(a), normals.getY(a), normals.getZ(a));
      NormalB.set(normals.getX(b), normals.getY(b), normals.getZ(b));
      NormalC.set(normals.getX(c), normals.getY(c), normals.getZ(c));

  
  
  
  if (
        verticesA.distanceToSquared(verticesB) < 0.000001 ||
        verticesA.distanceToSquared(verticesC) < 0.000001 ||
        verticesC.distanceToSquared(verticesB) < 0.000001
      ) {
        continue;
      }
  
      centroid.copy(verticesA).add(verticesB).add(verticesC).divideScalar(3);
  
      raycaster.set(centroid, dirc);
  
      intersects = raycaster.intersectObject(m, false);
      //  lineGo.vertices.push(centroid,dirc.divideScalar(.5).add(centroid))
      //  var linematerial = new THREE.LineBasicMaterial({color: 0x0000ff})
      //  var line = new THREE.Line( lineGo, linematerial );
      //  scene.add( line );
  
      if (intersects.length > 0) {
      // continue;
        orgn = verticesA;
        dirc.set(normals.getX(a), normals.getY(a), normals.getZ(a));
        //dirc.copy(centroid).sub(m.geometry.boundingSphere.center).normalize ()
        centor.copy(centroid).sub(orgn).normalize().negate()
        .divideScalar(2)
        dirc.add(centor).normalize();
        raycaster.set(centroid, dirc);
        intersects = raycaster.intersectObject(m, false);
        if (intersects.length > 0) {
          orgn = verticesB;
  
          dirc.set(normals.getX(b), normals.getY(b), normals.getZ(b));
          centor.copy(centroid).sub(orgn).normalize().negate()
          .divideScalar(2)
          dirc.add(centor).normalize();
          raycaster.set(orgn, dirc);
          intersects = raycaster.intersectObject(m, false);
  
          if (intersects.length > 0) {
            orgn = verticesC;
  
            dirc.set(positions.getX(c), positions.getY(c), positions.getZ(c));
            centor.copy(centroid).sub(orgn).normalize().negate()
            .divideScalar(2)
            dirc.add(centor).normalize();
            raycaster.set(orgn, dirc);
            intersects = raycaster.intersectObject(m, false);
  
            if (intersects.length > 0) {
              //toDelete.push(i)
              continue;
            }
          }
        }
      }
//////////////



      //geometry.faces[i].color.setRGB(1,0,0)
      newPositions.push(positions.getX(a), positions.getY(a), positions.getZ(a))
      newPositions.push(positions.getX(b), positions.getY(b), positions.getZ(b))
      newPositions.push(positions.getX(c), positions.getY(c), positions.getZ(c))

      newNormals.push(normals.getX(a), normals.getY(a), normals.getZ(a));
      newNormals.push(normals.getX(b), normals.getY(b), normals.getZ(b));
      newNormals.push(normals.getX(c), normals.getY(c), normals.getZ(c));

      newIndices.push( nextIndex );
      newIndices.push( nextIndex+1 );
      newIndices.push( nextIndex +2);
      nextIndex +=3;

    }
  
    // for ( var i = 0; i < toDelete.length; i++ ) {
    //     deletegeometry.faces[toDelete[i]];
    // }
  
    let g = new THREE.BufferGeometry();
  
    g.setAttribute("position", new THREE.Float32BufferAttribute(newPositions, 3));
    g.setAttribute("normal", new THREE.Float32BufferAttribute(newNormals, 3));
    g.setIndex(newIndices)
    console.log(g);
    //   geometry.faces = geometry.faces.filter( function(v) { return v; });
  
    // geometry.elementsNeedUpdate = true; // update faces
    // var mash =new THREE.Mesh(geometry,new THREE.MeshLambertMaterial())
    console.log("Hollow:"+indices.count+"to:"+nextIndex)
    // geometry.mergeVertices()
   
    //g.computeVertexNormals()
    // exportASCII(g)
    const tolerance= 2//Math.floor(g.getAttribute( 'position' ).count*.1)
    var modifer = new SimplifyModifier();
g=modifer.modify( g, tolerance);/*geometry.vertices.length * 0.9 | 0*/ 
console.log("modifer:"+ g.getAttribute( 'position' ).count)

g=getNormal(g)

g = mergeVertices(g);

console.log("merge:getNormal:" + g.getAttribute( 'position' ).count)
    
 return g;
    

  }
  export default MakeHollow
  export {mergeGeometry}


  function mergeGeometry(meshsArry) {


    const geometry = BufferGeometryUtils.mergeBufferGeometries(
      meshsArry.map((g) => new THREE.BufferGeometry().copy(g.geometry).translate(g.x, g.y, g.z))
    );
    geometry.computeBoundingSphere();
    

    const m= new THREE.Mesh(geometry, new THREE.MeshBasicMaterial())
    return MakeHollow(m)
    
  }
  