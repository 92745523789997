import * as THREE from "three";
function mergeVertices( geometry, tolerance = 1e-4 ,angel=50) {
    
    tolerance = Math.max( tolerance, Number.EPSILON );


    const hashToIndex = {};
	const indices = geometry.getIndex();
    
    const positions = geometry.getAttribute( 'position' );
    const normals = geometry.getAttribute( 'normal' );
   
    const vertexCount = indices ? indices.count : positions.count;
    let nextIndex = 0;

    const attributeNames = Object.keys( geometry.attributes );
	const attrArrays = {};
	const newIndices = [];
	const getters = [ 'getX', 'getY', 'getZ'];

    for ( let i = 0, l = attributeNames.length; i < l; i ++ ) {

		const name = attributeNames[ i ];

		attrArrays[ name ] = [];
    }

    const decimalShift = Math.log10( 1 / tolerance );
	const shiftMultiplier = Math.pow( 10, decimalShift );

    for ( let i = 0; i < vertexCount; i ++ ) {

        const index = indices ? indices.getX( i ) : i;

        let hash = '';
        let angleBN

        for ( let k = 0; k < 3; k ++ ) {

            hash += `${ ~ ~(positions[getters[ k ]](index) * shiftMultiplier ) },`; 
        }

        if ( hash in hashToIndex ) {
            //console.log(attrArrays)
            let verticesA = new THREE.Vector3(normals['getX'](index), normals['getY'](index), normals['getZ'](index));
            
            let verticesB = new THREE.Vector3(attrArrays.normal[hashToIndex[ hash ]*3],
                                            attrArrays.normal[hashToIndex[ hash ]*3+1],
                                            attrArrays.normal[hashToIndex[ hash ]*3+2])

            angleBN =verticesA.angleTo(verticesB) *180/3.14
            //console.log(angleBN)
            if (angleBN < angel ){
                verticesA=verticesA.addVectors(verticesA,verticesB).divideScalar(2); 
                
                attrArrays.normal[hashToIndex[ hash ]*3]=verticesA.x
                attrArrays.normal[hashToIndex[ hash ]*3+1]=verticesA.y
                attrArrays.normal[hashToIndex[ hash ]*3+2]=verticesA.z

                newIndices.push( hashToIndex[ hash ] );
                continue;  
            }
            
        } 


        for ( let j = 0, l = attributeNames.length; j < l; j ++ ) {

            const name = attributeNames[ j ];
            const attribute = geometry.getAttribute( name );
            const itemSize = attribute.itemSize;
            const newarray = attrArrays[ name ];
            
            for ( let k = 0; k < itemSize; k ++ ) {

                const getterFunc = getters[ k ];
                newarray.push( attribute[ getterFunc ]( index ) );

            }

        }

        hashToIndex[ hash ] = nextIndex;
        newIndices.push( nextIndex );
        nextIndex ++;
    }




    // Generate typed arrays from new attribute arrays and update
	// the attributeBuffers
	const result = geometry.clone();
	for ( let i = 0, l = attributeNames.length; i < l; i ++ ) {

		const name = attributeNames[ i ];
		const oldAttribute = geometry.getAttribute( name );

		const buffer = new oldAttribute.array.constructor( attrArrays[ name ] );
		const attribute = new THREE.BufferAttribute( buffer, oldAttribute.itemSize, oldAttribute.normalized );

		result.setAttribute( name, attribute );



	}

	// indices

	result.setIndex( newIndices );

	return result;
}  




 
function getNormal( geometry) {
        const newIndices = [];
        const _vA = new THREE.Vector3();
        const _vB = new THREE.Vector3();
        const _vC = new THREE.Vector3();
        
        const _ab = new THREE.Vector3();
        const _cb = new THREE.Vector3();
        const indices = geometry.getIndex();
        const positions = geometry.getAttribute( 'position' );
        
        const normalAttribute = new THREE.BufferAttribute( new Float32Array( indices.count * 3  ), 3 );
        geometry.setAttribute( 'normal', normalAttribute );
       
        
        const attributeNames = Object.keys( geometry.attributes );
        const attrArrays = {};
        //attrArrays['normal'] = [];

        

        for ( let i = 0, l = attributeNames.length; i < l; i ++ ) {

            const name = attributeNames[ i ];
    
            attrArrays[ name ] = [];
        }

        for ( let i = 0; i < indices.count/3; i ++ ) {
            
            const a = indices ? indices.getX( i*3 ) : i*3;
            const b = indices ? indices.getX( i*3+1 ) : i*3+1;
            const c = indices ? indices.getX( i*3+2 ) : i*3+2;

            attrArrays.position.push(positions.getX(a))
            attrArrays.position.push(positions.getY(a))
            attrArrays.position.push(positions.getZ(a))

            attrArrays.position.push(positions.getX(b))
            attrArrays.position.push(positions.getY(b))
            attrArrays.position.push(positions.getZ(b))

            attrArrays.position.push(positions.getX(c))
            attrArrays.position.push(positions.getY(c))
            attrArrays.position.push(positions.getZ(c))
        
			_vA.fromArray( [positions.getX(a),positions.getY(a),positions.getZ(a)] );
			_vB.fromArray( [positions.getX(b),positions.getY(b),positions.getZ(b)] );
			_vC.fromArray( [positions.getX(c),positions.getY(c),positions.getZ(c)] );

			_cb.subVectors( _vC, _vB );
			_ab.subVectors( _vA, _vB );
			_cb.cross( _ab );

			_cb.normalize();

            attrArrays.normal.push(_cb.x)
            attrArrays.normal.push(_cb.y)
            attrArrays.normal.push(_cb.z)

            attrArrays.normal.push(_cb.x)
            attrArrays.normal.push(_cb.y)
            attrArrays.normal.push(_cb.z)

            attrArrays.normal.push(_cb.x)
            attrArrays.normal.push(_cb.y)
            attrArrays.normal.push(_cb.z)

            
            newIndices.push( i*3 );
            newIndices.push( i*3+1 );
            newIndices.push( i*3+2 );

		}

   
        
        const result = geometry.clone();

        for ( let i = 0, l = attributeNames.length; i < l; i ++ ) {
    
            const name = attributeNames[ i ];
            const oldAttribute = geometry.getAttribute( name );
           
            const buffer = new oldAttribute.array.constructor( attrArrays[ name ] );
            const attribute = new THREE.BufferAttribute( buffer, oldAttribute.itemSize, oldAttribute.normalized );
    
            result.setAttribute( name, attribute );

        }
    

      //  const Nbuffer = new oldAttribute.array.constructor( attrArrays[ name ] );
        // indices
       // const buffer = new oldAttribute.array.constructor( attrArrays[ 'position' ] );
       // const Nattribute = new THREE.BufferAttribute( buffer, oldAttribute.itemSize, oldAttribute.normalized );


        result.setIndex( newIndices );
      
        return result;
    

        
}

    
    export {mergeVertices,getNormal,reduceForSave};



function reduceForSave( geometry) {
     let tolerance = 0;
     const radius =geometry.boundingSphere.radius;
console.log(radius);

     (0 < radius ) && (radius <= 0.2 ) && (tolerance = 1e-4 );
     (0.2 < radius ) && (radius <= 1 ) && (tolerance = 1e-3 );
     (1 < radius ) && (radius <= 3 ) && (tolerance = 2e-3 );
     (3 < radius ) && (radius <= 5 ) && (tolerance = 5e-3 );
     (5 < radius ) && (tolerance = 1e-2 );
     var verticesA = new THREE.Vector3(0, 0, 0);
     var verticesB = new THREE.Vector3(0, 0, 0);
     var verticesC = new THREE.Vector3(0, 0, 0);

     const decimalShift = Math.log10( 1 / Math.max( tolerance, Number.EPSILON ) );
     const shiftMultiplier = Math.pow( 10, decimalShift );
 
     
     
        

      
    
        const hashToIndex = {};
        const indices = geometry.getIndex();
        
        const positions = geometry.getAttribute( 'position' );
       
       
        const vertexCount = indices ? indices.count : positions.count;
        let nextIndex = 0;
    
        const attributeNames = Object.keys( geometry.attributes );
        const attrArrays = {};
        const newIndices = [];
        const getters = [ 'getX', 'getY', 'getZ'];
        attrArrays[ 'position' ] = [];
let i=0
 for ( let j = 0; j < vertexCount; j+=3 )
          {

        const a =indices ? indices.getX( j ) :j;
        const b =indices ? indices.getX( j+1 ):j+1;
        const c =indices ? indices.getX( j+2 ):j+2;

        verticesA.set(positions.getX(a), positions.getY(a), positions.getZ(a));
        verticesB.set(positions.getX(b), positions.getY(b), positions.getZ(b));
        verticesC.set(positions.getX(c), positions.getY(c), positions.getZ(c));

        if (
            verticesA.distanceTo(verticesB) < tolerance ||
            verticesA.distanceTo(verticesC) < tolerance ||
            verticesC.distanceTo(verticesB) < tolerance
          ) {
            i+=3;
          }else{

          for ( let h = 0; h < 3; h ++ ) {   

     //   for ( let i = 0; i < vertexCount; i ++ ) {
    
            const index = indices ? indices.getX( i ) : i;
    
            let hash = '';
            
    
            for ( let k = 0; k < 3; k ++ ) {
    
                hash += `${ ~ ~(positions[getters[ k ]](index) * shiftMultiplier ) },`; 
            }
            // Add another reference to the vertex if it's already
            // used by another index
            if ( hash in hashToIndex ) {
               
                    newIndices.push( hashToIndex[ hash ] );
                
            }else{ 
        
                const attribute = geometry.getAttribute(  'position' );
                const itemSize = attribute.itemSize;
                const newarray = attrArrays[  'position' ];
                
                for ( let k = 0; k < itemSize; k ++ ) {
    
                    const getterFunc = getters[ k ];
                    newarray.push( attribute[ getterFunc ]( index ) );
    
                }
    
            
        
                hashToIndex[ hash ] = nextIndex;
                newIndices.push( nextIndex );
                nextIndex ++;
            }
        i++;
        }
    }
    }
 
    
        // Generate typed arrays from new attribute arrays and update
        // the attributeBuffers
       // const result = geometry.clone();
             const oldAttribute = geometry.getAttribute(  'position' );
    
            const buffer = new oldAttribute.array.constructor(  );
            const attribute = new THREE.BufferAttribute( buffer,3);

    
         //   result.setAttribute(  'position', attribute );
         const vertices = new Float32Array(attrArrays[  'position' ].length);
         vertices.set([...attrArrays[  'position' ]]);
            const result =new THREE.BufferGeometry() ; 
    
            result.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
        // indices
    
        result.setIndex( newIndices );
        //result.computeVertexNormals ()
        return result;
}  
      

// const otherNewIndices = [];
// const OtherHashToIndex = {};
// console.log(`${newIndices[0] }p${newIndices[1] }p${newIndices[2]},`)
// let s=0
// for ( let j = 0,l =newIndices.length; j < l; j+=3 ){

//     let hash = `${newIndices[j] }p${newIndices[j+1] }p${newIndices[j+2]}`;
 

//     if (
//         `${newIndices[j]}p${newIndices[j+1]}p${newIndices[j+2]}` in OtherHashToIndex 
//     ){s++}else{ 

//     otherNewIndices.push( newIndices[j])
//     otherNewIndices.push( newIndices[j+1])
//     otherNewIndices.push( newIndices[j+2])
//     OtherHashToIndex[`${newIndices[j]}p${newIndices[j+1]}p${newIndices[j+2]}`]=j*2
//     OtherHashToIndex[`${newIndices[j]}p${newIndices[j+2]}p${newIndices[j+1]}`]=j*2+1
//     OtherHashToIndex[`${newIndices[j+1]}p${newIndices[j]}p${newIndices[j+2]}`]=j*2+2
//     OtherHashToIndex[`${newIndices[j+1]}p${newIndices[j+2]}p${newIndices[j]}`]=j*2+3
//     OtherHashToIndex[`${newIndices[j+2]}p${newIndices[j]}p${newIndices[j+1]}`]=j*2+4
//     OtherHashToIndex[`${newIndices[j+2]}p${newIndices[j+1]}p${newIndices[j]}`]=j*2+5
// }

// }
// console.log("got%"+(s/newIndices.length*300))