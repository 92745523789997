import * as THREE from "three";
import { Canvas, useFrame,mesh } from "@react-three/fiber";
import{ useEffect,  useMemo} from "react";
import { LineSegments2, LineSegmentsGeometry } from "three-stdlib";
import { LineSegments } from "three";

import { Detailed } from '@react-three/drei'

 
 function Outline({Assy, click,onAfterRender,uniAssembly}){

  //  const edges = new THREE.EdgesGeometry(geometr,45)
  //  const edges = useMemo(() => new THREE.EdgesGeometry(geometr,45)[geometr])
  const c =uniAssembly.geometry.boundingSphere.center

 return (
  <Detailed  distances={[0, Assy.geometry.boundingSphere.radius*4]}>
        <mesh> 
         </mesh>

<mesh geometry={Assy.geometry}   onDoubleClick={click} onAfterRender={onAfterRender}  
position={[c.x-uniAssembly.x+Assy.x,
           c.y-uniAssembly.y+Assy.y,
           c.z-uniAssembly.z+Assy.z]} >
       
        <meshBasicMaterial  color={0xffffff } depthFunc ={THREE.NeverDepth} depthWrite={false} depthTest={false} 
       
       // args={[{
      //vertexShader: conditionalLineVertShader,
       //fragmentShader: conditionalLineFragShader,
    //    uniforms: {
    //      diffuse: {
    //        value:new THREE.Color(0x2f4f4f ) 
    //      },
    //      opacity: {
    //        value: 1
    //      }
    //    },
    //    transparent: false
    // }]} 
    />
    </mesh>
  
    </Detailed>)
  
   
  }
  
  export default Outline;
  
  
      // var conditionalLineVertShader = /* glsl */`
      // attribute vec3 control0;
      // attribute vec3 control1;
      // attribute vec3 direction;
    // attribute float collapse;
  
      // #include <common>
      // #include <color_pars_vertex>
      // #include <fog_pars_vertex>
      // #include <logdepthbuf_pars_vertex>
      // #include <clipping_planes_pars_vertex>
      // void main() {
      // 	#include <color_vertex>
  
      // 	// Transform the line segment ends and control points into camera clip space
      // 	vec4 c0 = projectionMatrix * modelViewMatrix * vec4( control0, 1.0 );
      // 	vec4 c1 = projectionMatrix * modelViewMatrix * vec4( control1, 1.0 );
      // 	vec4 p0 = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      // 	vec4 p1 = projectionMatrix * modelViewMatrix * vec4( position + direction, 1.0 );
  
      // 	c0.xy /= c0.w;
      // 	c1.xy /= c1.w;
      // 	p0.xy /= p0.w;
      // 	p1.xy /= p1.w;
  
      // 	// Get the direction of the segment and an orthogonal vector
      // 	vec2 dir = p1.xy - p0.xy;
      // 	vec2 norm = vec2( -dir.y, dir.x );
  
      // 	// Get control point directions from the line
      // 	vec2 c0dir = c0.xy - p1.xy;
      // 	vec2 c1dir = c1.xy - p1.xy;
  
      // 	// If the vectors to the controls points are pointed in different directions away
      // 	// from the line segment then the line should not be drawn.
      // 	float d0 = dot( normalize( norm ), normalize( c0dir ) );
      // 	float d1 = dot( normalize( norm ), normalize( c1dir ) );
      // 	float discardFlag = float( sign( d0 ) != sign( d1 ) );
      
    //   vec3 p = position + ((discardFlag > 0.5) ? direction * collapse : vec3(0));    
    //   vec4 mvPosition = modelViewMatrix * vec4( p, 1.0 );
      // 	gl_Position = projectionMatrix * mvPosition;
  
      // 	#include <logdepthbuf_vertex>
      // 	#include <clipping_planes_vertex>
      // 	#include <fog_vertex>
      // }
      // `;
  
      // var conditionalLineFragShader = /* glsl */`
      // uniform vec3 diffuse;
      // uniform float opacity;
  
      // #include <common>
      // #include <color_pars_fragment>
      // #include <fog_pars_fragment>
      // #include <logdepthbuf_pars_fragment>
      // #include <clipping_planes_pars_fragment>
      // void main() {
      // 	#include <clipping_planes_fragment>
      // 	vec3 outgoingLight = vec3( 0.0 );
      // 	vec4 diffuseColor = vec4( diffuse, opacity );
      // 	#include <logdepthbuf_fragment>
      // 	#include <color_fragment>
      // 	outgoingLight = diffuseColor.rgb; // simple shader
      // 	gl_FragColor = vec4( outgoingLight, diffuseColor.a );
      // 	#include <tonemapping_fragment>
      // 	#include <encodings_fragment>
      // 	#include <fog_fragment>
      // 	#include <premultiplied_alpha_fragment>
      // }
      // `;
  
  