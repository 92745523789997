import MyContext,{root} from "../store/MyContext";
import React, {useContext,useEffect,useState} from 'react'

import './box.css';
function Box ({part,oPacity=.95,scale} ){
   
  return (part.gChildren.length >0 && <>
  

   <div className="contenedor"   style={{transform: `scale(${scale})`}}>
     <div style={{
       position:"absolute",
       height: "90px",
       width:"90px",
       opacity: "0.6",
      border:"1px solid #788baf",
       borderRadius: "45px",
       top:"-45px",
      left:"-20px",
       margin: "0px 40px",
       background: "radial-gradient(circle at 45px 45px, #e2e9f7, #788baf)"
       }}></div> 

     

<div className="cubo"  > 
 { part.gChildren.map((Item,i) => ( i<6 && Item.picFile!==null &&
<div className="cara" key={i}>
<img src={root+'/images/'+Item.picFile }  alt={"Soto y Caldevilla de Valdeón"}/>
</div>
))}
</div> 
</div>

  </>)
}

export default Box
