
import MyContext,{root} from "./store/MyContext";
import {  useContext ,useEffect,useState} from "react";
  import { Link } from "react-router-dom";
function  Home  ({location})  {
 
  //const myCtx = useContext(MyContext);
 //<h1 style ={{textAlign: 'center'}} >Knock down explorer  ... under constraction<img src={root+'/images/home.jpg'}  className="HomeImage" alt="Knock down Car" /> 
//</h1>


  return (<>
   
    <div className="home">
  
 
  

  <div className="Homenavbar">
        <div className="container">
            <a className="logo" href="#">Aret<span>Eng</span></a>

            {/* <img id="mobile-cta" className="mobile-menu" src="images/menu.svg" alt="Open Navigation"/> */}

            <nav className ="hnav">
                {/* <img id="mobile-exit" className="mobile-menu-exit" src="images/exit.svg" alt="Close Navigation"/> */}
                
                <ul className="primary-nav">
                    {/* <li className="current"><a href="#">Home</a></li> */}
                    <li><a href="#">Features</a></li>
                    <li><Link to="/About" >About </Link></li>
                    <li className="go-premium-cta"><a href="https://twitter.com">
                    <img style={{width:'20px'}} src={root+'/images/twitter.png'} />
                    </a></li>
                </ul>

                <ul className="secondary-nav">
                    
                </ul>
            </nav>
        </div>
    </div>
  

    </div>
         <img className="HomeImage" src={root+'/images/home.jpg'}   alt="Knock down Car" />  
         <div style={{paddingLeft :"330px",paddingTop :"80px", position: "absolute",opacity:.5}} >  
         <img style={{width:"80px"}} src={'/icon/I.jpg'}   alt="Knock down Car" />  
    <img style={{width:"80px"}} src={'/icon/NX.svg'}      alt="Knock down Car" />  
    <img style={{width:"80px"}} src={'/icon/SW.svg'}     alt="Knock down Car" />  </div>
    {/* <h2 className="baner" style ={{textAlign: 'center'}} >Knock<span>Down</span>Explorer</h2> */}

    </>);
};

export default Home;