import React, { useContext, useRef,useEffect,useState} from "react";
import {  Html} from "@react-three/drei";

import * as THREE from "three";

const Datum =React.forwardRef(({ datum,myCtx,history,center},ref) => {
    center===undefined && (center=new THREE.Vector3(0,0,0))
    function onClick(e){
     myCtx.setDatumSt(e)   
    }
    
    const [notExplod,setnotExplod]=useState(true )
    useEffect(() => {  
        ref.current={Update:()=>{}}
        ref.current.Update=function(T){setnotExplod(T)}

    },[ref]) 
    const scale=0.04*myCtx.getRadius()
    return(<mesh 
        //
    
        //position={ item[i]} quaternion={new THREE.Quaternion().copy(camera.quaternion)}

//scale={*5}
// 
    
    >{
    
        datum.map((item,index )=> (item.type==="dimension"?
        (<DimensionArrow key={index} index={index} scale={scale} onClick={onClick} a={new THREE.Vector3(item.ax,item.ay,item.az)} b={new THREE.Vector3(item.bx??0,item.by??0,item.bz??0)} 
item={item}
//
//
//projectedPx[i].angleTo(projected[i])
/>):(<InfoArrow key={index} index={index} scale={scale} onClick={onClick} 
a={notExplod ? new THREE.Vector3(item.ax,item.ay,item.az) : new THREE.Vector3(item.ax-item.bx,item.ay-item.by,item.az-item.bz)} 
b={!notExplod ? new THREE.Vector3(item.ax,item.ay,item.az) : new THREE.Vector3(item.ax-item.bx,item.ay-item.by,item.az-item.bz)} 
//b={new THREE.Vector3(item.ax,item.ay,item.az).multiplyScalar(2).sub(center)} 
item={item}

/>)

))}
     </mesh>) })



function DimensionArrow ({a,b,item,index,onClick,scale}){
    const points = [];
  
    const  distance= a.distanceTo(b)
   
const quaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1,0,0).normalize(),new THREE.Vector3().subVectors(a,b).normalize());
 const middle = new THREE.Vector3( -distance/scale/2, 3.5, 0 ).multiplyScalar(scale).applyQuaternion(quaternion).add(a) 

points.push( new THREE.Vector3( -1, 4, 0 ) );
points.push( new THREE.Vector3( 0, 3.5, 0 ) );

points.push( new THREE.Vector3( -1, 3, 0 ) );
points.push( new THREE.Vector3( 0, 3.5, 0 ) );

points.push( new THREE.Vector3( -3.5*distance/scale/8, 3.5, 0 ) );//
points.push( new THREE.Vector3( 0, 3.5, 0 ) );

points.push( new THREE.Vector3( 0, 4, 0 ) );
points.push( new THREE.Vector3( 0, 0, 0 ) );

const points1 = [];
points1.push( new THREE.Vector3( 1, 4, 0 ) );
points1.push( new THREE.Vector3( 0, 3.5, 0 ) );

points1.push( new THREE.Vector3( 1, 3, 0 ) );
points1.push( new THREE.Vector3( 0, 3.5, 0 ) );

points1.push( new THREE.Vector3( 3.5*distance/scale/8, 3.5, 0 ) );//
points1.push( new THREE.Vector3( 0, 3.5, 0 ) );

points1.push( new THREE.Vector3( 0, 4, 0 ) );
points1.push( new THREE.Vector3( 0, 0, 0 ) );

const points2 = [a,new THREE.Vector3( 0, 0, 0 )];



const geometry  = new THREE.BufferGeometry().setFromPoints( points).scale(scale,scale,scale).applyQuaternion (quaternion).translate(a.x,a.y,a.z);;
const geometry1 = new THREE.BufferGeometry().setFromPoints( points1).scale(scale,scale,scale).applyQuaternion (quaternion).translate(b.x,b.y,b.z);
const geometry2 = new THREE.BufferGeometry().setFromPoints( points2)

return(item.created_at!=="delete" &&<>
<line   geometry={geometry} >
<lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={1} linecap={'round'} linejoin={'round'} />
</line>
<line  geometry={geometry1} >
<lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={1} linecap={'round'} linejoin={'round'} />
</line>
<Html position={middle}  transform distanceFactor={scale*30}

//new THREE.Vector3().addVectors(a,b).multiplyScalar(0.5)quaternion={quaternion}
style={{left:"-30px",top:"-10px", position:"absolute"}
}
sprite
>

<div onClick={(e)=>{onClick(index)}} >{item.title} </div>
</Html>  
{/* <line  geometry={geometry2} >
<lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={1} linecap={'round'} linejoin={'round'} />
</line> */}
</>)

}
function InfoArrow ({a,b,item,index,onClick,scale}){
    const c = new THREE.Vector3().addVectors(a,b).multiplyScalar(0.5)
    const points = [a,c];
   
   // const  distance= a.distanceTo(b)
    
// points.push( new THREE.Vector3( -1, 1, 0 ) );
// points.push( new THREE.Vector3( 0, 0, 0 ) );

// points.push( new THREE.Vector3( -1, -1, 0 ) );
// points.push( new THREE.Vector3( 0, 0, 0 ) );

// points.push( new THREE.Vector3( -distance/scale, 0, 0 ) );//
// points.push( new THREE.Vector3( 0, 0, 0 ) );

// // points.push( new THREE.Vector3( 0, 4, 0 ) );
// // points.push( new THREE.Vector3( 0, 0, 0 ) );

const quaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1,0,0).normalize(),new THREE.Vector3().subVectors(a,b).normalize());
const geometry  = new THREE.BufferGeometry().setFromPoints( points)//.applyQuaternion (quaternion).translate(a.x,a.y,a.z);;

return(item.created_at!=="delete" && <>
    {/* <line   geometry={geometry}  >
    <lineBasicMaterial attach="material" color={'#fc3503'} linewidth={1} linecap={'round'} linejoin={'round'} />
    </line> */}
    
    <Html position={a} //quaternion={quaternion} 
    //transform distanceFactor={scale*30}
    //style={{left:"0px",top:"0px", position:"absolute"}}
    sprite
    >
   
    <div onClick={(e)=>{onClick(index)}}   className="wdgt" 
    style={{right:0,bottom:0}} 
    
    
    //
    
    >
     
      <div className="pin"  /> 
        <span className="pintip">{item.title} </span>
    
    
    </div>
    
    <div   className ={"pin_"+ item.type} ></div> 
   <div   className ={"pin_line"} ></div> 
    </Html>  
    {/* <line  geometry={geometry2} >
    <lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={1} linecap={'round'} linejoin={'round'} />
    </line> */}
    </>)

}
export default Datum;