import React, { useContext, useRef,useEffect,useState} from "react";
import { mesh  ,useThree} from "@react-three/fiber";
import Box from "./Box";
import {  Html} from "@react-three/drei";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import * as THREE from "three";
const Balloon= React.forwardRef(({AcvMech,myCtx,meshsArry,  
    onPointerOver,
    onPointerOut ,
    onClick,
    selectSt
},ref )=>{
    const [update,setUpdate]=useState(true ) 
    const [aOn,setAon]=useState(false )
    const [AcvMechup,setAcvMech]=useState(null )
   // const [boxDisplay,setboxDisplay]=useState(true )
  //  const [camera,setCamera]=useState(false )
   const { camera ,gl } = useThree();
   const refBox= useRef(null) 
   
   const arryBox =[];
  

  

    //const camera =cameraCont==null?false:cameraCont.object
    const mystyle= useRef({ opacity:.5} );
    useEffect(() => {  
        
              
        ref.current={Update:()=>{},anime:false,on:()=>{}}
                setAcvMech(AcvMech)  
        
                ref.current.Update=function(){setUpdate((p)=>!p) ;}
                ref.current.on=function(t){
        t && (t===aOn) && setUpdate((p)=>!p);
        setAon(t);             
        t?arryBox?.forEach((e)=>{e && (e.style.display="block") }):arryBox?.forEach(e=>{e && (e.style.display="none")})
     
        refBox.current && (refBox.current.visible=t);
       
        
                }


            },[ref,AcvMech,arryBox]) 

  const  projected =[]
  const  projectedPx =[]
  const yOrder=[]
  const xOrder=[]
  const lineGeometrys=[];
  const vPostion=[];
  const lPostion=[];
  const baner= new THREE.Vector3()
  const bom= new THREE.Vector3()
  let wZ= new THREE.Vector2() 
  const arM=[...AcvMech]
  gl.getSize(wZ)

  function eachBox(e){
    arryBox.push(e)
  }
  
  if (aOn ){
    let cx=0
    AcvMech.forEach((e, i) => {
        let j =new THREE.Vector3().copy(e.position).project(camera)
        if(j.y<1&& j.y>-1 && j.x<1 && j.x>-1){cx++}
        projected.push(j);
        
    })

    projected.forEach((e, i) => {
        let g = projected[i]
        let py=0
        if(g.y<1&& g.y>-1 && g.x<1 && g.x>-1){
            
        projected.forEach((element, index) => { 
            if(element.y < 1 && element.y>-1 && element.x<1 && element.x>-1){
                if( g.y >= element.y )py++;
            }
        })
    }
        ( py <= cx/2 )? yOrder.push(-1) : yOrder.push(1)
        
    })

    AcvMech.forEach((e, i) => {
        let p = new THREE.Vector3(0,0,0)
        let g = projected[i]
        let px=0
        let py=0
        const c =cx/2
        if(g.y<1&& g.y>-1 && g.x<1 && g.x>-1){

        projected.forEach((element, index) => { 
            if(element.y<1&& element.y>-1 && element.x<1 && element.x>-1){
                if(yOrder[index]* yOrder[i]>0  && g.x >= element.x )px++;
                //if(yOrder[index]==-1 && g.y < c && g.x > element.x )px++;
                if( g.y >= element.y )py++; 
            }   
        })
        p.z=g.z
        //if(py >= c){p.y=.9}else{p.y=-.9}
        //if( g.y<0){g.y<-1?e.y=-1.1:e.y=-.8+(py/cx*.3)} else {g.y>1?e.y= 1.1:e.y=.7+(py/cx*.3)}
       
        xOrder.push(px)
        p.x=((px-1)/cx)*5*(wZ.x-200)/wZ.x-.9
//
        if(py > c){p.y=.6+((py-c)/c*.2)}else{p.y=-(.93-((py)/cx*.3))}
        
       
    }else{p=g.multiplyScalar(1.1)} 
    const points = []  
     points.push(e.position)
     points.push(new THREE.Vector3(p.x*0.9,p.y*0.9,p.z).unproject(camera))
    lineGeometrys.push(new THREE.BufferGeometry().setFromPoints(points)) 
    if( i=== selectSt){
        bom.copy(p)
        bom.y=.5
bom.x=-.95
        //bom.x>0?bom.x=.6:
        bom.unproject(camera)     
    }
    projectedPx.push(new THREE.Vector3().subVectors(projected[i],p))
    p=p.unproject(camera)
    vPostion.push(p)
     

    })

}



   return (aOn && <mesh ref={refBox}>
{ AcvMech.map((a,i)=> ( (selectSt==null)&&
 <mesh key={i} > 
<Html key={i+150} style={{ left:"-45px",top:"-45px" }} position={ vPostion[i]} ref={eachBox}> 
<div
onPointerOver ={()=>onPointerOver(i) } onPointerOut={()=>onPointerOut(i)} onClick={()=>onClick(i)} 
>   
{ meshsArry[i].gChildren && meshsArry[i].gChildren.length > 0 &&
<Box oPacity={0.8} scale={.8} key ={i+200} part={meshsArry[i]} />} 
</div> 
</Html>  
<Html key={i+50} position={ vPostion[i]}  ref={eachBox}>
<div   key={i+50}>  

<p className="Dimensions" style={{ left:"-20px",top:"0px", position: "absolute" ,fontWeight:"bold"}} key={i+250}
 onPointerOver ={()=>onPointerOver(i) } onPointerOut={()=>onPointerOut(i)} onClick={()=>onClick(i)} 
>{meshsArry[i].name}
</p> 
</div> 
</Html>
<line  key={i+100} geometry={lineGeometrys[i]}>
<lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={1} linecap={'round'} linejoin={'round'} />


</line>


</mesh> ))}



{selectSt!==null && arM.length >0 && meshsArry[selectSt].gChildren!==undefined && <mesh >
  <Html  position={bom} ref={eachBox} > 

  <div
onPointerOver ={()=>onPointerOver(selectSt) }
 onPointerOut={()=>onPointerOut(selectSt)}
 onClick={()=>onClick(selectSt)} 
>  
<Box oPacity={1} scale={1.3}  part={meshsArry[selectSt]} />
  <div className="Dimensions"  style={{ fontWeight:"bold"}}>{meshsArry[selectSt].name}</div>
  {meshsArry[selectSt].gChildren.map((a,i)=> ( 
    
    <li className="Dimensions" style={{listStyle:'inside'}} key={i+250}>{a.name}</li>    
    ) )}</div></Html>
   
   </mesh>  }

</mesh> ) })
 
 
export default Balloon ;



